<template>
  <ReadPage :id="evaluationId" />
</template>

<script>
// Components
import ReadPage from "@/views/learning/evaluations/quizzes/ReadPage";

export default {
  components: {
    ReadPage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    evaluationId: {
      type: [String, Number],
      required: true
    }
  }
};
</script>
