<template>
  <Evaluation :id="id" :key="id" :type="evaluationTypes.QUIZ">
    <template #title>
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.quizzes", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.learning_evaluations_quizzes") }}
        </VHint>
      </VTitle>
    </template>
  </Evaluation>
</template>

<script>
// Components
import VTitle from "@/components/VTitle";
import VHint from "@/components/VHint";
import Evaluation from "../Evaluation";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    VTitle,
    VHint,
    Evaluation
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup() {
    return {
      evaluationTypes
    };
  }
};
</script>
